import React from "react";
import Layout from "./../Layout/Layout";
import Seperator from "./Seperator";
import Title from "./Title";
import HubTile from "./HubTile";


const Index = () => {
  return (
    <Layout>
    <Title />
      <HubTile />
      <Seperator />
    </Layout>
  );
};

export default Index;
