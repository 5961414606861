import React from "react";
import {Link} from "gatsby";

const HubTile = () => {
  return (
    <div className=" bg-green-default">
      <div className="max-w-7xl mx-auto px-1.5 md:px-10 pt-20 ">
        <div className=" ">
          <div className="text-white md:w-2/4  w-11/12 m-auto px-4 md:px-12 py-20 pb-25">
              <div className="md:text-2xl text-xl font-bold mb-3 mt-3">
              <ul>
              <li>Sorry 😔 we couldn’t find what you were looking for.<br /><br /> </li>
              <li>However, could we invite you to a round of <Link to="http://htmltetris.com/" target="__blank" className="underline">HTML Tetris?</Link> Don't forget to also take a look at our vision of <Link to="/" className="underline">free plant curtains for everybody!</Link><br /><br /> </li>
              </ul>
              </div>
              <p className="md:text-2xl text-xl font-bold mb-7">
              Let’s jungle the cities together! 💚 
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubTile;
