import React from "react";

function Seperator() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center mb-20">
        </div>

      </div>
    </div>
  );
}

export default Seperator;
